<template>
  <v-row wrap no-gutters>
    <credentials-select
      :key="`${step.id}-credentialname`"
      step-type="JS"
      :properties="properties"
      step-label="js"
      :readonly="!canEdit"
      :required="false"
      @change="handleChange('credentialName', $event)"
    />
    <libraries-select
      :key="`${step.id}-libraries`"
      step-type="JS"
      step-label="js"
      :properties="properties"
      :readonly="!canEdit"
      @change="handleChange('librariesNames', $event)"
    />
    <v-col cols="12" class="py-1">
      <add-key-value
        :key="`${step.id}-set`"
        step-type="JS"
        type="js"
        :can-edit="canEdit"
        :steps="steps"
        :single-step="step"
        :data="properties.set"
        @editor="$emit('openEditor', $event, 'set')"
        @dataChanged="handleChange('set', $event)"
      />
    </v-col>
  </v-row>
</template>

<script>
import { CredentialsSelect, LibrariesSelect } from './components'
import StepMixin from './StepMixin'
import AddKeyValue from '../KeyValPairModal'
import { castValueForStep } from '@/utils/helpers'

export default {
  name: 'JSStep',
  components: {
    CredentialsSelect,
    LibrariesSelect,
    AddKeyValue
  },
  mixins: [StepMixin],
  props: {
    step: {
      type: Object,
      default: () => {
        return null
      }
    },
    steps: {
      type: Array,
      default: () => []
    },
    canEdit: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleChange(field, value) {
      this.$set(this.properties, field, castValueForStep(
        field,
        value,
        {
          shouldBeString: ['credentialName'] // Specify credentialName for casting
        }
      ))
    }
  }
}
</script>
